import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import styles from "./index.module.less";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Footer from "@/components/Layout/Footer";
import { BANNER_LIST, DEVELOP_LIST, NEWS_LIST, CORE_LIST } from "@/consts/home";
import invest1 from "@/assets/images/home/invest1.png";
import invest2 from "@/assets/images/home/invest2.png";
import invest3 from "@/assets/images/home/invest3.png";
import logo1 from "@/assets/images/home/logo1.svg";
import logo2 from "@/assets/images/home/logo2.svg";
import logo3 from "@/assets/images/home/logo3.svg";
import logo4 from "@/assets/images/home/logo4.svg";
import contact1 from "@/assets/images/home/contact1.png";
import contact2 from "@/assets/images/home/contact2.png";
import contact3 from "@/assets/images/home/contact3.png";
import phone from "@/assets/images/home/phone.png";
import redBook from "@/assets/images/home/redBook.png";
import weChat from "@/assets/images/home/weChat.png";
import douyin from "@/assets/images/home/douyin.png";
import leftBtn from "@/assets/images/home/leftBtn.png";
import rightBtn from "@/assets/images/home/rightBtn.png";
import logo23 from "@/assets/images/home/logo23.png";
import TitleCom from "@/components/TitleCom";
import Manager from "@/components/Manager";
import WithPageMetaData from "@/components/WithPageMetaData";
import { httpMiddleNewsLists } from "@/api/home";

const platforms = [
  {
    id: 1,
    icon: weChat,
    // eslint-disable-next-line global-require
    qrcode: require("@/assets/images/qwQrcode.png"),
  },
  {
    id: 2,
    icon: douyin,
    // eslint-disable-next-line global-require
    qrcode: require("@/assets/images/dyQrcode.png"),
  },
  {
    id: 3,
    icon: redBook,
    // eslint-disable-next-line global-require
    qrcode: require("@/assets/images/xhsQrcode.png"),
  },
]

const Home = () => {
  SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);
  const navigate = useNavigate();

  const [bannerActive, setBannerActive] = useState(0);
  const bannnerSwiper = useRef();
  const [developActive, setDevelopActive] = useState(0);
  const developSwiper = useRef();
  const mySwiper = useRef();
  const [newsActive, setNewsActive] = useState(0);
  const newsSwiper = useRef();

  const [isHoveredIndex, setIsHoveredIndex] = useState(false);
  const [curQrcode, setCurQrcode] = useState(platforms[0]);

  const [newsLists, setNewsLists] = useState([]);

  const handleMouseEnter = (index) => {
    setIsHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setIsHoveredIndex(-1)
  }

  const chooseBannerTab = (val) => {
    setBannerActive(val)
    bannnerSwiper.current.swiper.slideTo(val)
  }

  const chooseNesTab = (val) => {
    setNewsActive(val)
    newsSwiper.current.swiper.slideTo(val)
  }

  const chooseDevelopTab = (val) => {
    setDevelopActive(val);
    developSwiper.current.swiper.slideToLoop(val, 500, false);
  };

  const getMiddleNewsLists = async () => {
    try {
      const res = await httpMiddleNewsLists({ id: 108 });
      if (res?.code === 200) {
        setNewsLists(res?.data);
      }
      console.log(res, "res667788");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getMiddleNewsLists();
  }, []);

  const jumpDetail = (id) => {
    navigate(`/detail?id=${id}`);
  };

  return (
    <div className={styles.home}>
      {/* banner */}
      <div className={styles.banner}>
        <div className={styles.bannerWrapper}>
          <div className={styles.descContent}>
            <div className={styles.info}>
              <div className={styles.title}>人之蕴蓄，由学而大</div>
              <ul>
                <li />
                <li />
                <li />
                <li />
              </ul>
              <div className={styles.desc}>
                <p>
                  <span>学大教育（股票代码000526.SZ）</span>
                  深圳A股主板上市公司，是一家专注于个性化教育的综合性教育文化产业集团。自2001年创立以来，学大教育秉承“以人为本、因材施教”的个性化教育理念，打造了包括个性化教育、职业教育、文化阅读、医教融合等丰富业务模式。
                </p>
                {/* <p>是A股市场上专注于“个性化教育”的集团公司。</p>
                <p>
                  学大教育自2001年创立以来秉承“以人为本、因材施教”的个性化教育理念，打造了包括个性化教育、全日制学校、职业教育、文化阅读、医教融合、在线教育等在内的丰富业务模式。
                </p> */}
              </div>
            </div>
            <div className={styles.swiperContent}>
              <div className={styles.swiperBtnContent}>
                <img src={leftBtn} className="leftBtn" alt="" />
                <img src={rightBtn} className="rightBtn" alt="" />
              </div>
              <div className={styles.swiperImg}>
                <Swiper
                  ref={bannnerSwiper}
                  {...{
                    effect: "fade",
                    fadeEffect: {
                      crossFade: true,
                    },
                    onSlideChange: (val) => {
                      setBannerActive(val.activeIndex)
                    },
                    navigation: {
                      nextEl: ".rightBtn",
                      prevEl: ".leftBtn",
                    },
                  }}
                >
                  {BANNER_LIST.map((item) => {
                    return (
                      <SwiperSlide key={item.value}>
                        <div
                          className={styles.item}
                          style={{ backgroundImage: `url(${item.banner})` }}
                        >
                          <div className={styles.itemDesc}>
                            <div className={styles.num}>{item.label}</div>
                            <div className={styles.title}>
                              <div className={styles.zh}>{item.title}</div>
                              {/* <div className={styles.en}>{item.desc}</div> */}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>
          </div>
          <div className={styles.operate}>
            <img className={styles.img} src={logo23} alt="" />
            <div className={styles.operateBtn}>
              {BANNER_LIST.map((item, index) => {
                return (
                  <div
                    key={item.value}
                    className={`${styles.itemBtn} ${
                      bannerActive === index ? styles.itemBtnActive : ""
                    }`}
                    onClick={() => {
                      if (index === 0) {
                        chooseBannerTab(index)
                      } else {
                        chooseBannerTab(index)
                      }
                    }}
                  >
                    {item.label}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      {/* develop */}
      <div className={styles.enterpriseHistory}>
        <TitleCom chinaName={"企业历程"} engName={"Enterprise history"} />
        <div className={styles.tab}>
          <div className="tab-wrapper">
            <div className="swiper-pagination">
              {DEVELOP_LIST?.map((item, index) => {
                return (
                  <span
                    className={`tab-item ${
                      developActive === index ? "tab-item-active" : ""
                    }`}
                    key={item.value}
                    onClick={() => {
                      chooseDevelopTab(index)
                    }}
                    onMouseEnter={() => {
                      developSwiper.current.swiper.autoplay.stop()
                    }}
                    onMouseLeave={() => {
                      developSwiper.current.swiper.autoplay.start()
                    }}
                  >
                    {item?.label}
                  </span>
                )
              })}
            </div>
            <div className="tab-line" />
          </div>
        </div>
        <div className={styles.content}>
          <Swiper
            ref={developSwiper}
            {...{
              spaceBetween: 30,
              slidesPerView: 3.1,
              autoplay: {
                delay: 0,
              },
              centeredSlides: true,
              loop: true,
              freeMode: true,
              speed: 3500,
              onSlideChange: (val) => {
                setDevelopActive(val.realIndex);
              },
              navigation: {
                nextEl: ".enterProse-next",
                prevEl: ".enterProse-prev",
              },
            }}
          >
            {DEVELOP_LIST.map((item) => {
              return (
                <SwiperSlide
                  key={`${item.value}_${item.label}`}
                  onMouseEnter={() => {
                    developSwiper.current.swiper.autoplay.stop()
                  }}
                  onMouseLeave={() => {
                    developSwiper.current.swiper.autoplay.start()
                  }}
                >
                  <img className={styles.item} src={item.img} alt="" />
                  {/* <div className={styles.item}>{item.label}</div> */}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      <div className={styles.managerWrapper}>
        <Manager />
      </div>
      <div className={styles.coreBusiness}>
        <TitleCom chinaName={"核心业务"} engName={"core business"} />

        <div className={styles.content}>
          <div className={styles.wrapperContent}>
            <Swiper
              ref={mySwiper}
              {...{
                spaceBetween: 14,
                slidesPerView: 3.2,
                navigation: {
                  prevEl: ".swiper-button-prev1",
                  nextEl: ".swiper-button-next1",
                },
              }}
            >
              {CORE_LIST.map((item) => {
                return (
                  <SwiperSlide key={item.value}>
                    <div
                      className={styles.item}
                      onMouseEnter={() => {
                        handleMouseEnter(item.value)
                      }}
                      onMouseLeave={() => handleMouseLeave(item.value)}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        navigate(item.key);
                      }}
                    >
                      <img src={item.img} alt="" />
                      <div
                        className={styles.overlay}
                        style={{
                          visibility:
                            isHoveredIndex === item.value ? "" : "hidden",
                        }}
                      />
                      <div className={styles.contentWrapper}>
                        <div
                          className={`${styles.content} ${
                            isHoveredIndex === item.value ? styles.hovered : ""
                          }`}
                        >
                          <div className={styles.titleContent}>
                            <p className={styles.title}>{item?.label}</p>
                            <p className={styles.tit}>{item?.tit}</p>
                          </div>
                          <div
                            className={`${styles.otherLine} ${
                              isHoveredIndex === item.value
                                ? styles.isShow
                                : styles.noShow
                            }`}
                          >
                            <div className={styles.desc}>{item.desc}</div>
                            <div className={styles.lists}>
                              {item.list?.map((ite) => {
                                return (
                                  <div
                                    className={styles.itemList}
                                    key={ite.value}
                                    onClick={(e) => {
                                      if (ite.url) {
                                        e.stopPropagation()
                                        window.open(ite.url)
                                      }
                                    }}
                                  >
                                    {ite.label}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <img
              src={leftBtn}
              style={{ zIndex: 999, position: "absolute" }}
              className="swiper-button-prev1"
              alt=""
            />
            <img
              src={rightBtn}
              style={{ zIndex: 999, position: "absolute" }}
              className="swiper-button-next1"
              alt=""
            />
          </div>
        </div>
      </div>

      {newsLists.length ? (
        <div className={styles.newsCenter}>
          <TitleCom chinaName={"新闻中心"} engName={"News center"} />
          {/* <div className={styles.tab}>
            <div className="tab-wrapper">
              <div className="swiper-pagination">
                {newsLists?.map((item, index) => {
                  return (
                    <span
                      className={`tab-item ${
                        newsActive === index ? "tab-item-active" : ""
                      }`}
                      key={item.value}
                      onClick={() => {
                        chooseNesTab(index);
                      }}
                    >
                      {item?.subTitle}
                    </span>
                  );
                })}
              </div>
              <div className="tab-line" />
            </div>
          </div> */}
          <div className={styles.newsContents}>
            <Swiper
              ref={newsSwiper}
              {...{
                spaceBetween: 14,
                slidesPerView: 2.35,
                initialSlide: 1,
                centeredSlides: true,
                navigation: {
                  prevEl: ".swiper-button-left",
                  nextEl: ".swiper-button-right",
                },
                onSlideChange: (val) => {
                  setNewsActive(val.activeIndex);
                },
              }}
            >
              {newsLists?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      className={`${styles.item} ${
                        newsActive === index ? styles.itemActive : ""
                      }`}
                      src={item?.cover}
                      alt=""
                      onClick={() => {
                        jumpDetail(item.id);
                      }}
                    />
                    <div className={styles.contentDesc}>
                      <div className={styles.desc}>{item?.title}</div>
                      <div className={styles.time}>{item?.publishedAt}</div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <img
              src={leftBtn}
              style={{ zIndex: 99, position: "absolute" }}
              className="swiper-button-left"
              alt=""
            />
            <img
              src={rightBtn}
              style={{ zIndex: 99, position: "absolute" }}
              className="swiper-button-right"
              alt=""
            />
          </div>
        </div>
      ) : null}

      <div className={styles.investLayout}>
        <TitleCom chinaName={"投资布局"} engName={"Investment distribut"} />
        <div className={styles.desc}>
          着眼教育未来，投资布局科技、智能化、信息化领域，推动教育全面发展
        </div>
        <div className={styles.imgContent}>
          <img src={invest1} className={styles.img} />
          <img src={invest2} className={styles.img} />
          <img src={invest3} className={styles.img} />
        </div>

        <div className={styles.customerLogos}>
          <div className={styles.customerLogosLogos}>
            <div className={styles.logoAll}>
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
            </div>
            <div className={styles.logoAll}>
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
              <img src={logo1} className={styles.logoItem} alt="" />
              <img src={logo2} className={styles.logoItem} alt="" />
              <img src={logo3} className={styles.logoItem} alt="" />
              <img src={logo4} className={styles.logoItem} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.contactUs}>
        <TitleCom chinaName={"联系我们"} engName={"Contact us"} />
        <div className={styles.imgContent}>
          <img
            src={contact1}
            onClick={() => {
              navigate("/campus");
            }}
            className={styles.img1}
            alt=""
          />
          <img
            src={contact2}
            onClick={() => {
              window.open("https://jobs-xueda.ourats.com/index/#anchor2");
            }}
            className={styles.img2}
            alt=""
          />
          <img
            src={contact3}
            onClick={() => {
              navigate("/campus");
            }}
            className={styles.img3}
            alt=""
          />
        </div>
        <div className={styles.contactWay}>
          <div className={styles.contactInfo}>
            <img src={phone} className={styles.phone} alt="" />
            <div className={styles.desc}>
              <p className={styles.p1}>400-062-4008</p>
              <p className={styles.p2}>学大教育全国免费咨询热线</p>
              <p className={styles.p3}>咨询时间：8:00-23:00（节假日不休）</p>
              <p className={styles.p4}>总部地址：北京市朝阳区樱辉科技中心</p>
            </div>
          </div>

          <div className={styles.internet}>
            <div className={styles.internetContent}>
              <li>
                {platforms?.map((item) => {
                  return (
                    <li
                      onClick={() => setCurQrcode(item)}
                      key={item.id}
                      className={item.id === curQrcode.id ? styles.active : ""}
                    >
                      <img src={item.icon} alt="" />
                    </li>
                  )
                })}
              </li>
            </div>
            <div className={styles.qrCode}>
              <img src={curQrcode.qrcode} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default WithPageMetaData(
  Home,
  "学大教育（官网）—个性化一对一辅导教育品牌！",
  "学大教育(股票000526)创立于2001年，是国内A股市场上专注于个性化教育的集团公司。学大教育秉承“以人为本、因材施教”的个性化教育理念，创新打造了个性化教育、职业教育、文化服务、信息化服务四大业务版块，旨在帮助更多用户实现成长、成才的梦想，成为让人信赖的文化和教育产业集团。学大教育官网xueda.com，欢迎大家随时对话学大教育咨询师！",
  "学大教育,学大,一对一辅导,高中辅导"
);

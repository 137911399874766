import service from "./interceptor";
import { message } from "antd";

function request(url, params, options, baseConfig, method) {
  return new Promise((resolve, reject) => {
    let data = {};

    if (method === "get") data = { params };
    if (method === "post" || method === "put") data = { data: params };
    const config = {
      url,
      method,
      ...data,
      ...baseConfig,
    };

    service(config)
      .then((res) => resolve(res))
      .catch((error) => {
        if (options && options.error) message.error(error.msg);
        reject(error);
      });
  });
}

// 封装GET请求
export function get(url, data = {}) {
  const { params, options, config } = data;

  return request(url, params, options, config, "get");
}
// 封装POST请求
export function post(url, data) {
  const { params, options, config } = data;

  return request(url, params, options, config, "post");
}
// 封装PUT请求
export function put(url, data) {
  const { params, options, config } = data;

  return request(url, params, options, config, "put");
}

export default {
  UPLOAD_BASEURL: process.env.REACT_APP_API_BASE_URL,
  get,
  post,
  put,
};

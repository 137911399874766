import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import SideNav from "./SideNav";

export default function BaseLayout() {
  const location = useLocation();
  const [showSideNav, setShowSideNav] = useState(false);

  const changeFold = () => {
    setShowSideNav(true);
  };

  const closeFold = () => {
    setShowSideNav(false);
  };

  useEffect(() => {
    const handleScrollToTop = () => window.scrollTo(0, 0);
    // 在组件卸载时取消滚动到顶部的操作
    return () => {
      if (!location.pathname.includes("/news")) {
        handleScrollToTop();
      }
    };
  }, [location]);
  return (
    <>
      <Header showSideNav={showSideNav} changeFoldFunc={changeFold} />
      <div
        style={{
          paddingTop: "83px",
          transition: "all 0.7s ease 0ms",
          left: showSideNav ? "-593px" : 0,
          position: "relative",
        }}
      >
        <Outlet />
      </div>
      <SideNav showSideNav={showSideNav} closeFold={closeFold} />
    </>
  );
}

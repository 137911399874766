import React, { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import routes from "./route";
import Loading from "@/components/Loading";
import BaseLayout from "@/components/Layout/BaseLayout";
import NoPage from "@/components/Layout/404.js";
import Home from '@/views/Home';

// 实现组件懒加载
const SuspenseComponent = ({ Component, ...props }) => {
  return (
    <Suspense fallback={<Loading /> || "加载中..."}>
      <Component {...props} />
    </Suspense>
  );
};

const renderChild = (routes1) => {
  return routes1.map((route) => {
    return {
      ...route,
      element: route.redirect ? (
        <Navigate to={route.redirect} />
      ) : (
        <div>
          <SuspenseComponent Component={lazy(route.element)} />
        </div>
      ),
      children: route.children ? renderChild(route.children) : undefined,
    };
  });
};

// const BaseRouter = () => {
//   const element = useRoutes([
//     {
//       path: "/",
//       element: <BaseLayout />,
//       children: [...renderChild()],
//     },
//   ]);
//   return element;
// };

// const BaseRouter = () => {
//   const element = useRoutes([
//     {
//       path: "/",
//       element: <BaseLayout />,
//       children: [...renderChild()],
//     },
//   ]);
//   return element;
// };

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [...renderChild(routes), { path: "*", element: <Home /> }],
  },
]);

export default router;

import manager from "@/assets/images/home/manager.png";
import styles from "./index.module.less";

const Manager = () => {
  return (
    <div className={styles.manageInfo}>
      <img className={styles.img} src={manager} alt="" />
      <div className={styles.manageContent}>
        <div className={styles.name}>
          <span>金鑫</span> <div className={styles.nameline} />
        </div>
        <div className={styles.desc}>学大教育创始人、董事长兼CEO</div>
        <div className={styles.line} />
        <div className={styles.descContent}>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>民进中央企业家联谊会副会长</p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>开明慈善基金会第四届理事会副理事长</p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>北京市中华职教社第二届社务委员会副主任</p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>中国教育发展战略学会教育大数据专业委员会副理事长</p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>中国民办教育协会培训教育专业委员会第三届理事会副理事长</p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>中国优生优育协会儿童脑健康专业委员会副主任</p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>
              中国民主促进会北京市第十六届委员会港澳台及海外联络委员会副主任
            </p>
          </div>
          <div className={styles.itemDesc}>
            <div className={styles.dot} />
            <p>光明《教育家》职业教育研究中心专家委员会副主任委员</p>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.thing}>
          <div className={styles.itemThing}>
            <div className={styles.dot} />
            <span className={styles.date}>2001年 |  </span> 
              金鑫创立学大教育
          </div>
          <div className={styles.itemThing}>
            <div className={styles.dot} />
            <span className={styles.date}>2010年  |  </span>
             带领学大成功登录美国纽交所上市
          </div>
          <div className={styles.itemThing}>
            <div className={styles.dot} />
            <div className={styles.word}>
              <span className={styles.date}>2016年 | </span>
              与清华紫光合作回归A股，名称变更为“紫光学大”
            </div>
          </div>
          <div className={styles.itemThing}>
            <div className={styles.dot} />
            <span className={styles.date}>2021年4月 | </span>
             金鑫重新成为上市公司实控人，上市公司名称变更为“学大教育”
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manager;

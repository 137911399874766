import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider, App } from "antd";
import zhCn from "antd/es/locale/zh_CN";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom"
import { Provider } from "react-redux";
import store from "./store";
import "dayjs/locale/zh-cn";
import "@/styles/index.less";
import router from "@/routes/index"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider
    locale={zhCn}
    theme={{
      token: {
        colorPrimary: "#FF3B30",
      },
      components: {
        Table: {
          headerBg: "#FF3B30",
          headerColor: "#fff",
        },
        Menu: {
          // Menu
          itemBg: "transparent",
          horizontalItemSelectedColor: "#FF3B30",
        },
        Timeline: {
          tailColor: "#00787F",
          itemPaddingBottom: "20px",
        },
      },
    }}
  >
    <Provider store={store}>
      <App>
        {/* <BrowserRouter>
          <BaseRouter />
        </BrowserRouter> */}
        <RouterProvider router={router} />
      </App>
    </Provider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from "axios";
import { getStorage, clearStorage } from "@/utils/storeage";
import { message } from "antd";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const UNLOGINCODE = [401];

const creatConfig = {
  baseURL,
  timeout: 10000,
  responseType: "json",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
};

const service = axios.create(creatConfig);

// 请求拦截
service.interceptors.request.use((config) => {
  const token = getStorage("token");

  if (token) {
    config.headers.Authorization = `Wx ${token}`;
  }
  // if (config.method === 'post') {
  //   config.data = {
  //     ...config.data
  //   }
  // } else if (config.method === 'get') {
  //   config.params = {
  //     ...config.params
  //   }
  // }
  return config;
});

// 返回拦截
service.interceptors.response.use(
  (response) => {
    if (response?.status !== 200) {
      message.error(response?.data?.errMessage || "服务异常");
      return response.data;
    } else {
      if(response.data.code === 401){
        clearStorage()
        window.location.href = `${window.location.origin}/loginRegister`;
        return Promise.reject(response.data.msg);
      }
      return response.data;
    }
  },
  (error) => {
    console.log(`error${error}`); // for debug
    let errMessage = "服务异常";

    if (error.message === "Network Error") {
      errMessage = "网络连接已断开，请检查网络";
    } else if (error.message.indexOf("timeout") > -1) {
      errMessage = "请求超时，请检查网络";
    }
    const res = error.response;

    if (UNLOGINCODE.includes(+res?.status)) {
      errMessage = "授权已过期，请重新登录";
      clearStorage()
      window.location.href = `${window.location.origin}/loginRegister`;

      return Promise.reject(error.response);
    }
    message.error(errMessage);
    return Promise.reject(error.response);
  }
);

export default service;

import develop1 from "@/assets/images/home/develop1.png";
import develop2 from "@/assets/images/home/develop2.png";
import develop3 from "@/assets/images/home/develop3.png";
import develop4 from "@/assets/images/home/develop4.png";
import develop5 from "@/assets/images/home/develop5.png";
import develop6 from "@/assets/images/home/develop6.png";
import develop7 from "@/assets/images/home/develop7.png";
import develop8 from "@/assets/images/home/develop8.png";
import develop9 from "@/assets/images/home/develop9.png";
import develop10 from "@/assets/images/home/develop10.png";

import news1 from "@/assets/images/home/news1.png"
import news2 from "@/assets/images/home/news2.png"
import news3 from "@/assets/images/home/news3.png"
import news4 from "@/assets/images/home/news4.png"
import news5 from "@/assets/images/home/news5.png"

import core1 from "@/assets/images/home/core1.png"
import core2 from "@/assets/images/home/core2.png"
import core3 from "@/assets/images/home/core3.png"
import core4 from "@/assets/images/home/core4.png"

import banner1 from "@/assets/images/home/banner1.png"
import banner2 from "@/assets/images/home/banner2.png"
import banner3 from "@/assets/images/home/banner3.png"
import banner4 from "@/assets/images/home/banner4.png"
import banner5 from "@/assets/images/home/banner5.png"
import banner6 from "@/assets/images/home/banner6.png"
import banner7 from "@/assets/images/home/banner7.png"

export const BANNER_LIST = [
  {
    label: "100+",
    value: 1,
    title: "覆盖城市",
    desc: "Covering city",
    banner: banner1,
  },
  {
    label: "300+",
    value: 2,
    title: "学习中心",
    desc: "Learning center",
    banner: banner2,
  },
  {
    label: "30+",
    value: 3,
    title: "全日制基地",
    desc: "Full-time base",
    banner: banner3,
  },
  {
    label: "15+",
    value: 4,
    title: "职业院校",
    desc: "Vocational education",
    banner: banner4,
  },
  {
    label: "13+",
    value: 5,
    title: "文化阅读空间",
    desc: "Cultural reading",
    banner: banner5,
  },
  {
    label: "5+",
    value: 6,
    title: "文谷学校",
    desc: "Covering city",
    banner: banner6,
  },
  {
    label: "7+",
    value: 7,
    title: "自营医院及诊所",
    desc: "Self-run hospital",
    banner: banner7,
  },
]

export const DEVELOP_LIST = [
  {
    label: "萌芽",
    value: 0,
    img: develop1,
  },
  {
    label: "探索",
    value: 1,
    img: develop2,
  },
  {
    label: "发展",
    value: 2,
    img: develop3,
  },
  {
    label: "增长",
    value: 3,
    img: develop4,
  },
  {
    label: "蜕变",
    value: 4,
    img: develop5,
  },
  {
    label: "升级",
    value: 5,
    img: develop6,
  },
  {
    label: "转型",
    value: 6,
    img: develop7,
  },
  {
    label: "跨越",
    value: 7,
    img: develop8,
  }, {
    label: "新生",
    value: 8,
    img: develop9,
  }, {
    label: "引领",
    value: 9,
    img: develop10,
  },
]

export const NEWS_LIST = [
  {
    label: "党建活动",
    value: 0,
    img: news1,
    desc: "学大教育个性化体系迈新阶，二十三年如一日为孩子们点亮梦想",
    time: "2024-09-19 14:29:21",
  },
  {
    label: "集团动态",
    value: 1,
    img: news2,
    desc: "学大教育句象书店上海首店落户陆家嘴，着力构建城市文化生活新坐标",
    time: "2024-10-22 15:58:10",
  },
  {
    label: "财报资讯",
    value: 2,
    img: news3,
    desc: "学大教育发布2024年度半年报：上半年营收16亿元，净利润同比增长超八成",
    time: "2024-08-30 14:17",
  },
  {
    label: "社会责任",
    value: 3,
    img: news4,
    desc: "学大教育集团董事长金鑫陪同民进中央领导一行赴浙江衢州调研",
    time: "2024-09-03 13:46:06",
  },
  {
    label: "学大荣誉",
    value: 4,
    img: news5,
    desc: "学大教育集团董事长金鑫受邀出席“永远的小白杨”公益音乐会",
    time: "2024-09-10 11:22:12",
  },
]

export const CORE_LIST = [
  {
    label: "个性化教育",
    tit: "Individualized education",
    value: 1,
    img: core1,
    desc: "以人为本、因材施教，提供以学生为中心的个性化教育服务。发掘个性优势，培养学习能力，提升综合素养，激发潜能，打造终身成长所需的知识和能力。",
    key:'/individuation',
    list: [
      { label: "个性化学业提升", value: 1 },
      { label: "全日制基地", value: 2 },
      { label: "文谷学校", value: 3 },
      { label: "学大在线", value: 4, url: "https://xdzx.xueda.com/" },
      { label: "拔尖人才培养", value: 5 },
    ],
  },
  {
    label: "职业教育",
    tit: "Vocational education",
    value: 2,
    img: core2,
    desc: "搭建中等职业教育、高等职业教育及产教融合、职业技能培训为一体的高素质技能人才贯通培养体系，打造多元化、高品质的职业教育品牌。",
    key: '/education',
    list: [
      { label: "中等职业教育", value: 1 },
      { label: "高等职业教育", value: 2 },
      { label: "产教融合", value: 3 },
      { label: "职业技能培训", value: 4 },
    ],
  },
  {
    label: "文化阅读",
    tit: "Cultural reading",
    value: 3,
    img: core3,
    desc: "打造全龄段、智能化的综合性文化阅读服务，助力实现全民阅读、推进终身学习。",
    key: '/culture',
    list: [
      { label: "句象书店", value: 1 },
      { label: "小象绘本阅读馆", value: 2 },
    ],
  },
  {
    label: "医教融合",
    tit: "Healthcare & Education",
    value: 4,
    img: core4,
    desc: "立足全人群的健康需求开展健康管理，针对生命不同阶段的主要健康问题及相应影响因素，确定若干优先干预领域，强化干预，提供从儿童到生命终点的全程健康服务和健康保障。",
    key: "/medical",
    list: [
      { label: "千翼健康", value: 1 },
      { label: "许昌千翼康复医院", value: 2 },
    ],
  },
]

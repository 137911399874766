import React, { useEffect } from 'react';

// 高阶组件，用于设置页面标题和描述
const withPageMetadata = (WrappedComponent, title, description, keywords) => {
    const WithPageMetadata = (props) => {
        useEffect(() => {
            document.title = title; // 设置页面标题
            // 设置页面描述的元标签
            const meta = document.querySelector('meta[name="description"]');
            if (meta) {
                meta.setAttribute('content', description);
            } else {
                const newMeta = document.createElement('meta');
                newMeta.setAttribute('name', 'description');
                newMeta.setAttribute('content', description);
                document.head.appendChild(newMeta);
            }
            // 设置页面keyword的元标签
            const wordsMeta = document.querySelector('meta[name="keywords"]');
            if (wordsMeta) {
                wordsMeta.setAttribute('content', keywords);
            } else {
                const newMeta = document.createElement('meta');
                newMeta.setAttribute('name', 'keywords');
                newMeta.setAttribute('content', keywords);
                document.head.appendChild(newMeta);
            }
        }, []);

        return <WrappedComponent {...props} />;
    };

    return WithPageMetadata;
};

export default withPageMetadata;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { getMenuInfo } from "@/api/home";
import phone from "@/assets/images/phone.png";
import logo from "@/assets/images/logo.png";
import fold from "@/assets/images/fold.png";
import { getQuery } from "@/utils";
import { HEADER_LIST } from "@/consts/header";
import SideNav from "../SideNav";
import styles from "./index.module.less";

const NavMenu = ({ changeFoldFunc, showSideNav }) => {
  const [selectedKey, setSelectedKey] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [currentSelectedKey, setCurrentSelectedKey] = useState(selectedKey);
  const [items, setItems] = useState(HEADER_LIST);
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(null);

  const dats = [
    {
      id: 1,
      siteId: 17,
      position: "home_top",
      name: "关于我们",
      nameEn: null,
      sort: null,
      parentId: 0,
      isLeaf: 0,
      menuType: "staticTemplate",
      param: null,
      children: [
        {
          id: 2,
          siteId: 17,
          position: "home_top",
          name: "学大介绍",
          nameEn: null,
          sort: null,
          parentId: 1,
          isLeaf: 1,
          menuType: "staticTemplate",
          param: "about",
          children: [],
        },
        {
          id: 4,
          siteId: 17,
          position: "home_top",
          name: "学大新闻",
          nameEn: null,
          sort: null,
          parentId: 1,
          isLeaf: 0,
          menuType: "articles",
          param: "4",
          children: [
            {
              id: 7,
              siteId: 17,
              position: "home_top",
              name: "学大新闻一",
              nameEn: null,
              sort: null,
              parentId: 4,
              isLeaf: 1,
              menuType: "articles",
              param: "7",
              children: [],
            },
            {
              id: 8,
              siteId: 17,
              position: "home_top",
              name: "学大新闻二",
              nameEn: null,
              sort: null,
              parentId: 4,
              isLeaf: 1,
              menuType: "articles",
              param: "8",
              children: [],
            },
            {
              id: 9,
              siteId: 17,
              position: "home_top",
              name: "学大新闻三",
              nameEn: null,
              sort: null,
              parentId: 4,
              isLeaf: 1,
              menuType: "articles",
              param: "9",
              children: [],
            },
          ],
        },
        {
          id: 5,
          siteId: 17,
          position: "home_top",
          name: "联系学大",
          nameEn: null,
          sort: null,
          parentId: 1,
          isLeaf: 1,
          menuType: "staticTemplate",
          param: "setting:22",
          children: [],
        },
      ],
    },
  ];

  const init = async () => {
    try {
      // const res = await getMenuInfo();
      // if (res?.code === 200) {
      const list = [
        {
          name: "网站首页",
          param: "home",
          id: "",
        },
        // ...res.data?.home_top,
        ...dats,
      ]?.map((item) => {
        return {
          ...item,
          label: item.name,
          key: item.param,
          children: item?.children
            ? item?.children.map((ite) => {
                return { ...ite, key: ite.param, label: ite.name };
              })
            : undefined,
        };
      });
      localStorage.setItem("navList", JSON.stringify(list));
      setItems(list);
      // }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    // init();
  }, []);

  // 获取当前url
  useEffect(() => {
    const name = location.pathname.slice(1);
    const id = getQuery("id");
    setCurrentSelectedKey(name);
    setSelectedKey(id);
    if (location.hash) {
      setCurrent(`${name}${location.hash}`);
      return;
    }
    setCurrent(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, getQuery("id")]);

  const handleItemClick = ({ parentKey, childKey, menuType }) => {
    setSelectedKey(childKey.toString());
    // // 这里可以添加其他点击事件的处理逻辑，比如页面跳转
    setCurrentSelectedKey(parentKey);
    // if(menuType === "articles")
    navigate(`/${parentKey}?id=${childKey}&menuType=${menuType}`);
  };

  const toggleSubMenu = (key) => {
    setOpenSubMenu(key);
  };

  const handelClick = () => {
    navigate("/");
  };

  const changeFold = () => {
    changeFoldFunc();
  };

  return (
    <>
      <div
        className={styles.header}
        style={{
          transition: "all 0.7s ease 0ms",
          left: showSideNav ? "-593px" : 0,
        }}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.leftWrap}>
            <img
              onClick={handelClick}
              src={logo}
              className={styles.logo}
              alt=""
            />
            <div className={styles.menuInfos}>
              <div className={styles.menusWrap}>
                <Menu
                  selectedKeys={[current]}
                  mode="horizontal"
                  // onClick={({ item: item1, key, keyPath, domEvent }) => {
                  //   console.log("object :>> ", item1, key, keyPath, domEvent);
                  //   // navigate(e.key);
                  //   // setCurrent(e.key);
                  // }}
                  // items={items}
                  items={items.map((item) => {
                    return {
                      key: item.key,
                      label: (
                        <div
                          // onClick={() => {
                          //   if (item.key !== "1") {
                          //     const { origin } = window.location;
                          //     window.location.href = `${origin}${item.key}`;
                          //   }
                          // }}
                          onClick={() => {
                            if (!item?.children) {
                              navigate(item?.key);
                              setCurrent(item?.key);
                            }
                          }}
                          style={{ fontSize: "16px" }}
                        >
                          {item.label}
                          {item.children ? (
                            <></>
                          ) : // <DownOutlined style={{ marginLeft: 10 }} />
                          null}
                        </div>
                      ),
                      children: item.children
                        ? item.children.map((child) => {
                            return {
                              key: child.key,
                              label: (
                                <div
                                  style={{ fontSize: "16px" }}
                                  onClick={() => {
                                    console.log(item, "item");

                                    // if (item.key === 'about') {
                                    //   navigate('about');
                                    //   setCurrent(child?.key);
                                    //   return;
                                    // }
                                    if (child.key === "invite") {
                                      window.open(
                                        "https://jobs-xueda.ourats.com/index/#anchor2"
                                      );
                                    } else {
                                      navigate(child.key);
                                      setCurrent(child?.key);
                                    }
                                  }}
                                >
                                  {child.label}
                                </div>
                              ),
                            };
                          })
                        : undefined,
                    };
                  })}
                />
              </div>
            </div>
          </div>
          <img src={fold} className={styles.fold} onClick={changeFold} alt="" />
        </div>

        {/* <SideNav showSideNav={showSideNav} closeFold={closeFold} /> */}
      </div>
    </>
  );
};

export default NavMenu;

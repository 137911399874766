const HEADER_LIST = [
  {
    id: 1,
    label: "首页",
    key: "",
  },
  {
    id: 2,
    label: "关于学大",
    key: "about",
    children: [
        {
            label: '学大简介',
            key: "about#brief",
            id: 'about#brief'
        },
        {
            label: '发展历程',
            key: "about#history",
            id: 'about#history'
        }, {
            label: '创始人',
            key: "about#founder",
            id: 'about#founder'
        }
        , {
            label: '教育理念',
            key: "about#idea",
            id: 'about#idea'
        }
    ]
  },
  {
    id: 3,
    label: "核心业务",
    key: "about3",
    children: [
      {
        label: "个性化教育",
        key: "individuation",
        id: "3-1",
      },
      {
        label: "职业教育",
        key: "education",
        id: "3-2",
      },
      {
        label: "文化阅读",
        key: "culture",
        id: "3-3",
      },
      {
        label: "医教融合",
        key: "medical",
        id: "3-4",
      },
    ],
  },
  // {
  //     id: 4,
  //     label: '投资布局',
  //     key: "4"
  // },
  {
    id: 5,
    label: "新闻中心",
    key: "news",
    children: [
      {
        label: "党建活动",
        key: "activity",
        id: "5-1",
      },
      {
        label: "集团动态",
        key: "news/dynamic",
        id: "5-2",
      },
      {
        label: "财报资讯",
        key: "news/financial",
        id: "5-3",
      },
      {
        label: "社会责任",
        key: "news/society",
        id: "5-4",
      },
      {
        label: "学大荣誉",
        key: "honor",
        id: "5-5",
      },
    ],
  },
  {
    id: 6,
    label: "联系我们",
    key: "us",
    children: [
      {
        label: "校区分布",
        key: "campus",
        id: "6-1",
      },
      {
        label: "招贤纳士",
        key: "invite",
        id: "3-2",
      },
    ],
  },
];

export { HEADER_LIST };

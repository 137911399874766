import { useMemo, useRef } from "react";
import { HEADER_LIST } from "@/consts/header";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.less";

const SideNav = ({ showSideNav, closeFold }) => {
  const navWrapRef = useRef();
  const navigator = useNavigate();

  const changeShowNav = () => {
    closeFold();
  };
  const sideNavWrapperStyle = useMemo(() => {
    if (showSideNav) {
      return {
        zIndex: 100,
        opacity: 1,
        position: "fixed",
      };
    } else {
      return {
        zIndex: -1,
        opacity: 0,
      };
    }
  }, [showSideNav]);

  const sideNavStyle = useMemo(() => {
    if (showSideNav) {
      return {
        right: 0,
      };
    } else {
      return {
        right: "-600px",
      };
    }
  }, [showSideNav]);

  const clickRoute = (item, ite) => {

    if (!!item && !!ite) {
      // if (item.key === 'about') {
      //   navigator(`/about`);
      //   closeFold();
      //   return
      // }
      if (ite.key === 'invite') {
        window.open('https://jobs-xueda.ourats.com/index/#anchor2')
      } else {
        navigator(`/${ite.key}`)
      }
      closeFold();
    }


  }

  return (
    <div className={styles.side}>
      {showSideNav ? (
        <div
          className={styles.sideNavWrap}
          onClick={changeShowNav}
          style={sideNavWrapperStyle}
          ref={navWrapRef}
        />
      ) : null}
      <div className={styles.sideNav} style={sideNavStyle}>
        <div className={styles.sideTitle}>网站地图</div>
        <div className={styles.sideContent}>
          <div className={styles.sideList}>
            {HEADER_LIST.slice(1).map((item) => {
              return (
                <div className={styles.itemSide} key={item.key}>
                  <div className={styles.itemTitle} onClick={() => {
                    clickRoute(item);
                  }}>
                    <div className={styles.dot} />
                    {item.label}
                  </div>
                  {item.children?.map((ite) => {
                    return (
                      <div
                        className={styles.itemName}
                        key={ite.key}
                        onClick={() => {
                          clickRoute(item, ite);
                        }}
                      >
                        <div className={styles.dot} />
                        <span>{ite.label}</span>
                      </div>
                    );
                  })}
                  {/* <div className={styles.sideTitle}></div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;

import { getStorage } from "./storeage";

/** 获取页面参数
 * @param {Object} name
 * @param {Object} url
 * @return {string} res
 */
export function getQuery(name, url = window.location) {
  const _URL = new URL(url);
  const _serach = new URLSearchParams(_URL.search);

  return _serach.get(name);
}

// 获取当前登录主体
export const getAppId = () => {
  return getStorage("xd_appid") || 1;
};

/**
 * 手机号加密
 */
export const encryptionText = (text) => {
  if (!text) {
    return "";
  }
  return `${text.substr(0, 3)}****${text.substr(7)}`;
};

import React from "react";
import styles from "./index.module.less";

export default function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footerTop}>
          <div className={styles.footerIem}>
            <div>个性化一对一</div>
            <div>个性化小班课</div>
          </div>
          <div className={styles.footerIem}>
            <div>全日制学习</div>
            <div>新高考选科</div>
          </div>
          <div className={styles.footerIem}>
            <div>全国免费咨询热线：400-062-4008</div>
            <div>咨询时间：8:00-23:00（节假日无休）</div>
          </div>
          <div className={styles.footerIem}>
            <div>违法和不良信息举报电话：400-102-8926</div>
            <div>举报邮箱：info@xueda.com</div>
          </div>
          <div className={styles.footerIem}>
            <div>网上有害信息举报专区</div>
            <div />
          </div>
        </div>
        <div className={styles.footerBottom}>
          经营许可证编号：京ICP备10045583号-6 | 京ICP证100956 |
          京公网安备11010502031324号 | 增值电信业务B2-20100091
          <br />
          Copyright @ 北京学大信息技术集团有限公司 All Rights Reserved
        </div>
      </footer>
    </>
  );
}

import request from "@/utils/request";

// 获取详情
export const httpDetail = (id) => {
    return request.get(`/api/post/${id}`);
};


/**
 * 获取新闻中心
 * @returns 
 */
export const httpMiddleNewsLists = (params) => {
    return request.get(`/api/site/tag?code=middleNews`)
    // return request.get(`/api/post/list/${params.id}`, {
    //     params: {
    //         pageSize: params?.pageSize || 10,
    //         pageNum: params?.pageNum || 1,
    //     },
    // });
}


/**
 * 获取子分类
 * @param {*} id
 * @returns
 */
export const httpGetCategory = (id) => {
    return request.get(`/api/post/nav/${id}`);
};
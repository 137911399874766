const routes = [
  {
    path: "/detail",
    title: "详情",
    element: () => import("@/views/Detail"),
  },
  {
    path: '/inviteDetail',
    element: () => import('@/views/InviteDetail')
  },
  {
    path: '/campus',
    element: () => import('@/views/Campus')
  },
  {
    path: '/invite',
    element: () => import('@/views/Invite')
  },
  {
    path: '/honor',
    title: '学大荣誉',
    element: () => import('@/views/News/Honor'),
  },
  {
    path: '/news',
    element: () => import('@/views/News'),
    children: [
      {
        path: '/news/dynamic',
        title: '集团动态',
        element: () => import('@/views/News/Dynamic')
      },
      {
        path: '/news/financial',
        title: '财报资讯',
        element: () => import("@/views/News/Financial")
      },


      {
        path: '/news/society',
        title: '社会责任',
        element: () => import('@/views/News/Society')
      },
    ]
  },
  {
    path: '/activity',
    title: '党建活动',
    element: () => import("@/views/Activity")
  },
  {
    path: '/individuation',
    title: '个性化教育',
    element: () => import("@/views/Individuation")
  },
  {
    path: '/culture',
    title: '文化阅读',
    element: () => import("@/views/Culture"),
  }, {
    path: '/education',
    title: '职业教育',
    element: () => import("@/views/Education"),
  }, {
    path: '/medical',
    title: '医教融合',
    element: () => import("@/views/Medical"),
  },
  {
    path: '/about',
    title: '关于学大',
    element: () => import("@/views/About"),
  },
  {
    path: "/",
    title: "首页",
    element: () => import("@/views/Home"),
  },
];

export default routes;

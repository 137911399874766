import styles from "./index.module.less";

const TitleCom = ({ chinaName, engName }) => {
  return (
    <div className={styles.TitleCom}>
      <div className={styles.TitleWrapper}>
        <div className={styles.leftLine}></div>
        <div className={styles.chinaTit}>{chinaName}</div>
        <div className={styles.rightLine}></div>
      </div>
      {/* <div className={styles.engTit}>{engName}</div> */}

    </div>
  );
};

export default TitleCom;
